.width100 {
  width: 100%;
  min-width: 1200px;
}
.width1200 {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.header {
  /* position: absolute;
  top: 0; */
  width: 100%;
  min-width: 1200px;
  height: 66px;
  z-index: 998;
}
.header-nav {
  position: relative;
  width: 100%;
  height: 66px;
  color: #fff;
  border-bottom: 1px solid rgba(102, 102, 102, 0.05);
}
.header-nav:hover {
  background: #fff;
  color: #262626;
  transition: all 0.5s;
}
.header-logo {
  position: absolute;
  top: 19px;
  left: 30px;
}
.header-logo-pic {
  display: flex;
  align-items: center;
  width: 230px;
  height: 30px;
  text-indent: -999em;
}
.header-nav .header-logo .header-logo-pic .logo1 {
  margin-right: 10px;
}
.header-nav .header-logo .header-logo-pic .logo1 .logo1-img1 {
    display: block;
}
.header-nav .header-logo .header-logo-pic .logo1 .logo1-img2 {
    display: none;
}
.header-nav:hover .header-logo .header-logo-pic .logo1 .logo1-img1 {
    display: none;
}
.header-nav:hover .header-logo .header-logo-pic .logo1 .logo1-img2 {
    display: block;
}

.header-nav .header-logo .header-logo-pic .logo2 .logo2-img1 {
  display: block;
}
.header-nav .header-logo .header-logo-pic .logo2 .logo2-img2 {
  display: none;
}
.header-nav:hover .header-logo .header-logo-pic .logo2 .logo2-img1 {
  display: none;
}
.header-nav:hover .header-logo .header-logo-pic .logo2 .logo2-img2 {
  display: block;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  .header-logo-pic {
    display: flex;
    align-items: center;
    width: 230px;
    height: 30px;
    text-indent: -999em;
  }
}
.header-menu {
 
  height: 66px;
  margin-left: 328px;
}
.header-menu .header-menu-item {
  height: 66px;
  position: relative;
  display: inline-block;
}
.header-menu .header-menu-item.active .header-title {
  margin-top: 22px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.header-menu .header-menu-item.active .header-title:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.header-menu .header-menu-item.active:after {
  content: '';
  width: 16px;
  height: 3px;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -8px;
}
.header-menu .header-title {
  display: block;
  margin-top: 23px;
  padding: 0 25px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.header-menu .header-title[href='javascript:void(0)'] {
  cursor: default;
}
.header-nav:hover .header-menu .header-title {
  color: #888;
}
.header-menu .header-title:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.header-title:hover .header-menu-slide {
  display: none;
}
.header-menu-slide-list-about{
  width: 140px;
  padding: 8px 0;
  background: #fff;
}
.icon-youjiantou{
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 10px;
  background: url('https://img.juhe.cn/www/v10/header/jiantou.svg') no-repeat;
  opacity: 0;
  filter: alpha(opacity = 0);
  transition: all .5s;
}

.header-menu-slide-item-other-about:hover{
  color: #07AAFF;
  background: #f6faff;
}
.header-menu-slide-item-other-about{
  display: block;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  font-size: 14px;
  color: #262626;
  background: rgba(246,250,255,0);
  /* transition: all .5s; */
  /* -webkit-transition: all .5s; */
}

.header-menu .header-menu-item .header-menu-slide {
  display: none;
  position: absolute;
  padding-top: 5px;
  left: 200px;
  top: 66px;
  /* left: 0px\9; */
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 999;
}
.header-menu .header-menu-item:last-child .header-menu-slide {
  left: 50%;
  right: 0;
}
/* .header-menu .header-menu-slide:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 4px;
  border-color: #fff #fff transparent transparent;
  top: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(-45deg);
  transform: translateX(-50%) rotate(-45deg);
  -webkit-box-shadow: 1px -2px 4px rgba(102, 102, 102, 0.2);
  box-shadow: 1px -2px 4px rgba(102, 102, 102, 0.2);
} */
.header-menu .header-menu-item .header-menu-slide-list {
  display: flex;
  flex-direction:column;
  min-height: 380px;
  min-width: 380px;
  padding: 30px 22px 16px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.0800);
  border-radius: 4px;
  -webkit-box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.0800);
}
.header-menu .header-menu-item:nth-child(2)  .header-menu-slide-list {
  width: 400px;
}
.header-menu .header-menu-item:last-child  .header-menu-slide-list{
  width: 140px;
  padding: 8px 0;
  background: #fff;
}
.header-menu .header-menu-slide-item-other {
  display: block;
  height: 60px;
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  box-sizing: border-box;
  padding: 14px;
  position: relative;
  width: 100%;
}
.header-menu .header-menu-slide-item-other:hover {
  /* opacity: 0.5; */
  background: #f6faff;
}
.header-menu .header-menu-slide-item-other .header-menu-slide-item-other-header {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}
.header-menu .header-menu-slide-item-line {
  height: 1px;
  background: #F5F5F5;
  margin-top: 16px;
  margin-bottom: 16px;
}
.header-menu .header-menu-slide-item-other img {
  margin-right: 16px;
}
.header-menu .header-menu-slide-item-other .header-menu-slide-item-other-header .header-menu-slide-item-other-title{
  font-size: 14px;
  font-weight: 700;
  color: #262626;
  line-height: 1.5;
}
.header-menu .header-menu-slide-item-other .header-menu-slide-item-other-header .header-menu-slide-item-other-desc {
  font-size: 12px;
  font-weight: 400;
  color: #888888;
  line-height: 1.5;
}
/* .header-menu .header-menu-slide-item-other .iconArrow {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.header-menu .header-menu-slide-item-other:hover .iconArrow{
  display: block;
} */
.header-menu .header-menu-slide-item {
  /* display: block; */
  /* margin: 0 auto; */
  /* height: 39px; */
  /* line-height: 39px; */
  /* border-bottom: 1px solid #e7e7e7; */
  font-size: 14px;
  color: #666;
  white-space: nowrap;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  /* padding-left: 20px; */
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &>div,ul{
    width: 100%;
  }
}

.border-bottom-style{
  border-bottom: 1px solid #eee;
  margin-bottom: 12px;
}

.header-menu .header-menu-slide-item  .header-menu-slide-item-name{
  display: block;
  padding-left: 14px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  /* color: #07AAFF; */
  color: #262626;
  font-weight: 700;
}

.header-menu .header-menu-slide-item>ul:hover .icon-youjiantou,.header-menu-slide-item-other:hover span {
  /* opacity: 0.5;
  color: #00BDFF;
  background: #f5f5f5; */
  opacity: 1;
}

.header-action {
  position: absolute;
  right: 30px;
  top: 0px;
}
.header-action .header-search {
  position: relative;
  float: left;
  height: 66px;
}
.header-nav .header-action .header-search-btn .img-black {
  display: none;
}
.header-nav .header-action .header-search-btn .img-white {
  display: block;
}
.header-nav:hover .header-action .header-search-btn .img-black {
  display: block;
}
.header-nav:hover .header-action .header-search-btn .img-white {
  display: none;
}
.header-action .header-search-btn img {
  margin-left: 12px;
}
.header-action .header-search-btn {
  position: absolute;
  left: 0;
  width: 25px;
  height: 41px;
  padding-top: 25px;
  padding-right: 10px;
  font-size: 0;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  text-align: right;
}
.header-action .header-search-btn:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.header-action .header-search-btn .iconfont {
  color: #fff;
  display: none\9;
}
.header-nav:hover .header-action .header-search-btn .iconfont {
  color: #262626;
}
.header-action .header-search-btn span {
  color: #666;
  font-size: 12px;
}
.header-action .header-search-container {
  float: right;
  height: 66px;
  overflow: hidden;
}
.header-action .header-search .search-input {
  padding-left: 30px;
}
.header-action .header-search:hover .search-input {
  width: 240px;
  padding-left: 30px;
}
.header-nav .header-action .search-input {
  background: rgba(255, 255, 255, 0.15);
  color: #FFFFFF;
  padding-left: 30px;
}
.header-action .search-input {
  width: 180px;
  height: 32px;
  border-radius: 4px;
  float: right;
  margin-top: 15px;
  margin-right: 24px;
  -webkit-appearance: none;
  border: none;
  outline: none;
  /* background: rgba(255, 255, 255, 0.1);
  background: rgb(239, 239, 239) \9; */
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header-nav:hover .header-action .search-input {
  background: rgba(187, 187, 187, 0.15);
  color: #262626;
}
.header-action .search-input:focus {
  width: 168px;
  padding-left: 30px;
}
.header-action input::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.header-action .juhe-api {
  float: left;
  font-size: 14px;
  line-height: 20px;
  margin-top: 22px;
  margin-right: 20px;
  margin-left: 8px;
}
.header-action .juhe-api a {
  color: #fff;
}
.header-action .header-line {
  float: left;
  width: 2px;
  height: 14px;
  margin: 24px 12px;
  background: rgba(255, 255, 255, 0.2);
  filter: alpha(opacity=10);
}
.header-nav:hover .header-action .header-line {
  background: rgba(0, 0, 0, 0.1);
}
.header-action .header-status {
  float: left;
}
.header-action .header-status1 {
  float: left;
}
.header-action .header-btn1 {
  display: inline-block;
  margin: 20px 7px;
  /* padding: 0 15px;
  height: 22px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 13px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 22px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; */
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
.header-action .header-btn {
  display: inline-block;
  margin: 20px 7px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
.header-nav:hover .header-action .header-btn:hover {
  color: #888;
}
.header-nav:hover .header-action .header-btn1:hover {
  color: #888;
}
.header-nav:hover .header-action .header-btn1 {
  /* border: 2px solid rgba(0, 0, 0, 0.1); */
  color: #262626;
}
.header-nav:hover .header-action .header-btn {
  /* border: 2px solid rgba(0, 0, 0, 0.1); */
  color: #262626;
}
.header-action .header_register:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.header-action .header-btn2 {
  position: relative;
  display: inline-block;
  width: 82px;
  height: 32px;
  margin-left: 24px;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: #ED510A;
  border-radius: 4px;
}
.header-action .header-btn2 .header-btn-badge {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 19px;
  height: 19px;
  padding: 0 4px;
  line-height: 19px;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  background: #fe514a;
  right: -8px;
  top: -11px;
}
.header-action .header-btn2:hover {
  /* background: #fff;
  border: 2px solid #fff; */
}
/* .header-action .header-btn2[href$='register']:after {
  content: '';
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  position: absolute;
  right: -3px;
  top: -3px;
} */
.hot::before {
  content: '';
  position: absolute;
  right: 0;
  top: 10px;
  height: 15px;
  width: 22px;
  background: url('https://juheimg.oss-cn-hangzhou.aliyuncs.com/www/v7/headerFooter/hot.png')
    no-repeat;
}
.main-container {
  position: relative;
  min-width: 1200px;
  width: 100%;
}
.tabel {
  float: right;
  margin-top: 30px;
}
.tabel > a {
  float: left;
  display: block;
  line-height: 20px;
  font-size: 14px;
  color: #fff;
  margin-right: 60px;
  cursor: pointer;
}
.heade {
  width: 100%;
  z-index: 10;
  position: absolute;
}
.container-content {
  background-color: #fff;
}
.container-content .why {
  background-color: #016cfa;
  width: 100% 0;
  position: relative;
}
.container-content .why .why-bg {
  height: 796px;
  margin: 0 auto;
  background: url('https://juheimgs.oss-cn-beijing.aliyuncs.com/scan/why-bg.png') no-repeat top
    center;
  background-color: #016cfa;
  background-size: cover;
  padding-top: 134px;
  position: relative;
}
.container-content .why .why-bg .why-main {
  margin: 0 auto;
}
.container-content .why .why-bg .why-main > div {
  text-align: center;
}
.container-content .why .why-bg .why-main .why-title {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  line-height: 70px;
  margin-bottom: 3px;
}
.container-content .why .why-bg .why-main .why-brief {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 24px;
}
.container-content .why .why-bg .why-main .why-btn {
  width: 140px;
  height: 40px;
  line-height: 40px;
  color: #207cf6;
  font-size: 14px;
  border-radius: 4px;
  margin: 0 auto;
  background-color: #fff;
  cursor: pointer;
  display: block;
  text-align: center;
}
.container-content .why .why-mask {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 0px solid transparent;
  border-bottom: 120px solid #fff;
}
.features {
  max-width: 966px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
}
.features .features-title {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #333;
  line-height: 60px;
}
.features .features-main {
  height: 388px;
  margin-top: 22px;
}
.features .features-main > div {
  float: left;
}
.features .features-main .features-pic,
.features .features-main .warning-pic {
  width: 618px;
  height: 100%;
  margin-right: 14px;
}
.features .features-main .features-pic img,
.features .features-main .warning-pic img {
  width: 100%;
  height: 100%;
}
.features .features-main .features-introduce {
  padding-top: 32px;
}
.features .features-main .features-introduce img {
  width: 72px;
  height: 72px;
}
.features .features-main .features-introduce p {
  font-size: 36px;
  color: #333;
  line-height: 54px;
  margin-top: 20px;
}
.features .features-main .features-introduce div {
  font-size: 14px;
  color: #666;
  line-height: 20px;
  margin-top: 10px;
}
.threaten {
  margin-top: 80px;
  padding-top: 0;
}
.threaten .features-main > div {
  float: right;
}
.threaten .features-introduce {
  padding-left: 40px;
}
.warning {
  margin-top: 80px;
  padding-top: 0;
}
.plugin {
  height: 280px;
  margin-top: 104px;
  padding-top: 60px;
  background-color: #016cfa;
}
.plugin .plugin-main {
  max-width: 996px;
  margin: 0 auto;
  position: relative;
}
.plugin .plugin-main h3 {
  font-size: 44px;
  color: #fff;
  line-height: 66px;
  font-weight: bold;
  margin-bottom: 5px;
}
.plugin .plugin-main p {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  margin-bottom: 22px;
  position: relative;
  z-index: 10;
}
.plugin .plugin-main div {
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}
.plugin .plugin-main img {
  position: absolute;
  width: 844px;
  height: 826px;
  right: 0px;
  top: -322px;
}
.scene {
  width: 100%;
  max-width: 966px;
  margin: 100px auto 0;
}
.scene .scene-title {
  font-size: 44px;
  color: #333;
  line-height: 66px;
  font-weight: bold;
  text-align: center;
}
.scene .scene-list {
  margin-top: 40px;
}
.scene .scene-list > ul {
  float: left;
  width: 456px;
  margin-bottom: 48px;
}
.scene .scene-list > ul > li {
  float: left;
}
.scene .scene-list > ul > .pic {
  width: 72px;
  height: 72px;
  margin-right: 20px;
}
.scene .scene-list > ul > .pic img {
  width: 100%;
  height: 100%;
}
.scene .scene-list > ul > .content {
  width: 364px;
}
.scene .scene-list > ul > .content > p:first-child {
  font-size: 18px;
  line-height: 28px;
  color: #333;
  font-weight: bold;
}
.scene .scene-list > ul > .content > p:last-child {
  font-size: 14px;
  color: #666;
  line-height: 20px;
  margin-top: 6px;
}
.scene .scene-list > ul:nth-child(3n + 0),
.scene .scene-list > ul:first-child {
  margin-right: 52px;
}
.tip {
  width: 100%;
  max-width: 996px;
  height: 196px;
  border-radius: 18px;
  background-color: rgba(1, 108, 250, 0.06);
  margin: 52px auto 40px;
}
.tip .tip-con {
  padding-top: 40px;
  font-size: 32px;
  color: #333;
  line-height: 48px;
  text-align: center;
  font-weight: bold;
}
.tip .tip-btn {
  width: 140px;
  height: 48px;
  border-radius: 8px;
  background-color: #016cfa;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  margin: 20px auto 0;
  cursor: pointer;
  display: block;
  text-align: center;
}
